<template>
  <v-dialog
    v-model="dialog"
    justify="cneter"
    :width="getWidth"
    persistent
    :fullscreen="isFullscreen"
    :retain-focus="false" 
  >
    <!-- Add Button  -->
    <template v-slot:activator="{ on, attrs }">
      <slot
        v-if="customAddButton"
        name="addButton"
        :attrs="attrs"
        :on="canShowAddButton && on"
        :click="changeSavedStatus"
      >
      </slot>

      <v-btn
        v-else
        v-show="canShowAddButton"
        color="primary"
        dark
        v-bind="attrs"
        v-on="canShowAddButton && on"
        @click="changeSavedStatus"
        >{{ buttonText }}
      </v-btn>
    </template>

    <!-- Dialog Content -->
    <v-card id="dialog" class="main-container" :height="getHeight">
      <!-- Title -->
      <v-toolbar
        dark
        :color="isError ? 'red' : 'primary'"
        class="main-container__title"
      >
        <v-btn icon dark @click="closeDialog(true)" :disabled="isLoading">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-btn
          icon
          dark
          @click="toggleFullscreen()"
          :disabled="isLoading"
          class="ml-5"
          v-if="toggleSize"
        >
          <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
          <v-icon v-else>mdi-fullscreen</v-icon>
        </v-btn>

        <v-toolbar-title>{{ getTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-progress-circular v-if="isLoading" indeterminate />
        <v-toolbar-items v-if="!openToView && !isError">
          <slot
            v-if="customSaveButton"
            name="saveButton"
            :click="onSave"
            :disabled="isLoading"
          >
          </slot>
          <template v-else>
            <v-btn dark text @click="onSave" :disabled="isLoading">
              حفظ {{ !hideSubmitButtons ? 'واغلاق' : '' }}
            </v-btn>
            <v-btn
              v-if="!hideSubmitButtons"
              dark
              text
              @click="onSaveWithNew"
              :disabled="isLoading"
            >
              حفظ وجديد
            </v-btn>
            <v-btn
              v-if="!hideSubmitButtons"
              dark
              text
              @click="onSaveWithoutClose"
              :disabled="isLoading"
            >
              حفظ
            </v-btn>
          </template>
        </v-toolbar-items>
      </v-toolbar>
      <!-- Body -->
      <v-progress-linear
        :active="isLoading"
        :color="loadingColor"
        indeterminate
      />
      <v-card-text
        v-if="showDialogOrDestroy"
        class="main-container__body"
        :style="[isHideDetail ? { display: 'none' } : {}]"
      >
        <slot
          :submitWithClose="submitWithClose"
          :submitWithNew="submitWithNew"
          :submitWithoutClose="submitWithoutClose"
          :closeDialog="slotStatus"
          :isEdit="openToEdit"
          :isView="openToView"
          :isNew="!openToEdit && !openToView"
        >
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { showSnackbarWarningMessage } from '@/utls/snackbar';
import { mapState } from 'vuex';
import { mapActions } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      default: 'اضافة',
    },
    buttonText: {
      type: String,
    },
    editTitle: {
      type: String,
      default: 'تعديل',
    },
    viewTitle: {
      type: String,
      default: 'عرض',
    },
    openToEdit: {
      type: Boolean,
      default: false,
    },
    openToView: {
      type: Boolean,
      default: false,
    },
    toggleSize: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    hideDetailOnLoading: {
      type: Boolean,
      default: true,
    },
    hideSubmitButtons: {
      // hide submitWithNew && submitWithoutClose
      type: Boolean,
      default: false,
    },
    width: {},
    height: {},
    showAddButton: {},
    customAddButton: {},
    customSaveButton: {},
  },
  data() {
    return {
      submitWithCloseCount: 0,
      submitWithoutCloseCount: 0,
      submitWithNewCount: 0,
      dialog: false,
      // dialogSaved: false,
      hideDetail: true,
      isError: false,
      isFullscreen: false,
      forceShowDialog: false,
    };
  },
  computed: {
    canShowAddButton() {
      if (this.showAddButton === false) return false;
      if (this.buttonText == undefined) return false;
      return true;
    },
    getWidth() {
      return this.width || '90%';
    },
    getHeight() {
      return this.height || '90vh';
    },
    ...mapState({
      isLoading: (state) => state.loading.dialog,
      isDark: (state) => state.theme.dark,
    }),
    loadingColor() {
      if (this.isDark) return 'primary lighten-5';
      return 'primary darken-10';
    },
    isHideDetail() {
      // TODO : Refactor
      if (!this.hideDetailOnLoading) return;
      return this.isError || this.isHideOnlyFirstLoading;
    },
    isHideOnlyFirstLoading() {
      return this.isLoading && this.hideDetail;
    },
    getTitle() {
      if (this.openToEdit) return this.editTitle;
      if (this.openToView) return this.viewTitle;
      return this.title;
    },
    submitWithClose() {
      return `submit${this.submitWithCloseCount}`;
    },
    submitWithNew() {
      return `submit${this.submitWithNewCount}`;
    },
    submitWithoutClose() {
      return `submit${this.submitWithoutCloseCount}`;
    },

    showDialogOrDestroy() {
      // true : show and not destroy it
      // false : not show and destroy it
      // const showWithKeepData = this.dialog || !this.dialogSaved;
      // return showWithKeepData;
      return this.dialog;
    },
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    onSave() {
      this.forceShowDialog = false;
      if (this.hideDetail) this.hideDetail = false;
      this.submitWithCloseCount++;
    },
    onSaveWithNew() {
      this.forceShowDialog = true;
      if (this.hideDetail) this.hideDetail = false;
      this.submitWithNewCount++;
    },
    onSaveWithoutClose() {
      this.forceShowDialog = false;
      if (this.hideDetail) this.hideDetail = false;
      this.submitWithoutCloseCount++;
    },
    changeSavedStatus() {
      if (!this.canShowAddButton) {
        showSnackbarWarningMessage('لاتوجد صلاحية');
        return;
      }
      // if dialog is saved data success, we need to clear input. back it as new
      // if (this.dialogSaved) this.dialogSaved = false;
    },
    closeDialog(isClose) {
      if (this.$store.state.sorting.isSortChanged) {
        this.openConfirm({
          title: 'تنبيه',
          message: 'لم يتم حفظ التغييرات, هل تريد الخروج؟',
          confirm: () => {
            this.dialog = !isClose;
            setTimeout(() => {
              this.resetSettings();
              this.$emit('onCloseDialog');
            }, 100);
            this.$store.commit('sorting/UPDATE_SORTING', false);
            return true;
          },
        });
      } else {
        this.dialog = !isClose;
        setTimeout(() => {
          this.resetSettings();
          this.$emit('onCloseDialog');
        }, 100);
      }
    },
    resetSettings() {
      this.hideDetail = true;
      this.isError = false;
      // this.isFullscreen = false;
    },
    slotStatus(isClose, isError) {
      this.isError = isError;

      if (!isClose) return;
      this.closeDialog(isClose);
      // this.dialog = !isClose;
      // if (isClose) this.dialogSaved = true;
    },
    openForDetail(open) {
      if (this.forceShowDialog) this.dialog = true;
      else this.dialog = open;
    },
    fullSizeDialog(isFull) {
      this.isFullscreen = isFull;
    },
    toggleFullscreen() {
      this.fullSizeDialog(!this.isFullscreen);
    },
    openDialog() {
      this.dialog = true;
    },
  },
  watch: {
    openToEdit(open) {
      this.openForDetail(open);
    },
    openToView(open) {
      this.openForDetail(open);
    },
  },
  created() {
    if (this.fullscreen) this.isFullscreen = true;
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__title {
    flex: 0;
    z-index: 1000;
  }
  &__body {
    height: 100%;
    padding-bottom: 0 !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    overflow: auto;
    scroll-behavior: smooth;
  }
}
</style>
